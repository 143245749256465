<template>
  <div>
    <div class="box">
      <div class="main">
        <div class="search">
          <el-button type="primary" @click="addRole">+ 新建角色</el-button>
        </div>
        <div class="primary">
          <el-table
              ref="multipleTable"
              :data="tableData"
              tooltip-effect="dark"
              style="width: 100%; margin-bottom: 10px"
              height="600px"
              :header-cell-style="{
              color: '#333333',
              backgroundColor: 'rgb(240, 247, 253)',
            }"
              border
          >
            <el-table-column type="selection" width="50" align="center">
            </el-table-column>
            <el-table-column
                type="index"
                label="序号"
                width="60"
                align="center"
            ></el-table-column>
            <el-table-column
                prop="OrganizationName"
                label="采购组织"
                min-width="100"
                align="center"
            >
            </el-table-column>
            <el-table-column
                prop="Name"
                label="角色名称"
                min-width="150"
                align="center"
            >
            </el-table-column>
            <el-table-column label="操作" width="150" align="center">
              <template slot-scope="scope">
                <el-button
                    :disabled="scope.row.Type == 'D'"
                    type="text"
                    style="margin-right: 10px; font-size: 16px; font-weight: 400"
                    @click="EditJurisdiction(scope.row)"
                >编辑
                </el-button
                >
                <el-button
                    :disabled="scope.row.Type == 'D'"
                    type="text"
                    style="font-size: 16px; font-weight: 400"
                    @click="DeleteJurisdiction(scope.row)"
                >删除
                </el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="block">
            <Pagination
                :total="total"
                :page.sync="listQuery.page"
                :limit.sync="listQuery.rows"
                @pagination="GetPageList"
            ></Pagination>
          </div>
          <div>
            <el-dialog
                title="提示"
                :visible.sync="dialogVisible"
                v-if="dialogVisible"
                @close="cancel"
                width="50%"
            >
              <div slot="title" class="header-title">
                <span class="title-name"></span>
                <span class="title-age">角色维护</span>
              </div>
              <div>
                <div style="margin-bottom: 30px">
                  <span
                      style="
                      margin-left: 40px;
                      font-size: 16px;
                      margin-right: 10px;
                    "
                  >输入角色</span
                  >
                  <span style="width: 300px; display: inline-block">
                    <el-input
                        v-model="Name"
                        placeholder="请输入内容"
                        change="span_input"
                    ></el-input>
                  </span>
                </div>
                <div style="
                    margin-left: 40px;
                    margin-bottom: 30px;
                    position: relative;
                    font-size: 16px;
                  "
                >
                  <div class="all">
                    <el-checkbox
                        v-model="checkAll"
                        @change="handleCheckAllChange"
                    >全选
                    </el-checkbox
                    >
                  </div>
                  <div
                      v-for="(item, index) in arr"
                      :key="index"
                      style="display: flex"
                  >
                    <div
                        :class="{ item_p_0: index == 0 }"
                        style="margin-right: 20px"
                    >
                      <el-checkbox
                          @change="handleCheckChange($event, item, index)"
                          :checked="calculation(item)"
                          v-model="selectItemCheck[index]"
                          ref="leftCheck"
                      ></el-checkbox>
                    </div>
                    <div
                        v-for="(row, i) in item"
                        :key="i"
                        style="display: flex"
                    >
                      <div :style="i == 0 ? 'width: 80px;' : ''">
                        <p
                            style="font-size: 16px; margin-bottom: 6px"
                            :class="{ item_p_0: index == 0 }"
                        >
                          {{ i == 0 ? row.ModuleName : "" }}
                        </p>
                      </div>
                      <div class="row_div">
                        <p>{{ index == 0 ? row.FunctionName : "" }}</p>
                        <el-checkbox
                            v-model="row.flag"
                            @change="ChangeJurisdiction($event, row)"
                        ></el-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
                <div style="margin-left: 40px">
                  <el-radio-group v-model="DataScope">
                    <el-radio label="1">本组织</el-radio>
                    <el-radio label="2">本项目</el-radio>
                    <el-radio label="3">本中心</el-radio>
                    <el-radio label="4">本人</el-radio>
                  </el-radio-group>
                </div>
              </div>
              <div slot="footer" class="btn">
                <button @click="cancel">取消</button>
                <button @click="determine">确定</button>
              </div>
            </el-dialog>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import administrators from "../../api/administrators"
import Pagination from "@/components/Pagination/index";

export default {
  data() {
    return {
      show: false,
      flag: false,
      checkAll: false,
      dialogVisible: false,
      tableData: [],
      Moduleslist: [],
      Functionslist: [],
      Name: "",
      organization: [],
      OrganizationId: "",
      permissions: [],
      DataScope: '1',
      type: 1,
      row: {},
      list: [],
      arr: [],
      total: 0,
      listQuery: {
        Key: "",
        page: 1,
        rows: 20,
      },
      selectItemCheck: [],
    }
  },
  // computed: {
  //   checkAllSelector: {
  //     get() {
  //       this.checkAll = this.selectItemCheck.every((item) => item)
  //       return ""
  //     },
  //     set(newVal) {
  //       this.checkAll = newVal
  //     }
  //   }
  // },
  created() {
    this.GetPageList()
    this.GetMap()
  },
  components: {
    Pagination,
  },
  methods: {
    calculation(item) {
      let result = null
      result = item.every(row => {
        return row.flag == true
      })
      return result
    },
    handleCheckChange(e, item, index) {

      if (e) {
        item.forEach(row => {
          // if(!row.flag){
          this.permissions.push({
            ModuleId: row.ModuleId,
            FunctionId: row.FunctionId,
            index: index
          })
          // };
          this.$set(row, 'flag', true)
        })
      } else {
        item.forEach(row => {
          this.$set(row, 'flag', false)
        })
        let ModuleId = item[0].ModuleId
        // console.log(this.permissions)
        this.permissions = this.permissions.filter(item => {
          return item.ModuleId !== ModuleId
        })
      }
      console.log(this.permissions, 'this.permissions');
    },
    //全选
    handleCheckAllChange(val) {
      this.checkAll = val
      if (val) {
        // this.$refs.leftCheck.forEach((item,index)=>{
        //   console.log(item,'checked');
        // })
        this.show = true
        this.arr.forEach((item, index) => {
          item.forEach(row => {
            this.permissions.push({
              ModuleId: row.ModuleId,
              FunctionId: row.FunctionId,
            })
            this.$set(row, 'flag', true)
            this.$set(row, 'index', index)
          })
        })
      } else {
        // this.show=false
        this.arr.forEach(item => {
          item.forEach(row => {
            this.$set(row, 'flag', false)
          })
        })
        this.permissions = []
      }
    },
    //角色列表
    GetPageList() {
      const parameter = {
        Key: "",
        page: this.listQuery.page,
        rows: this.listQuery.rows,
      }
      administrators.GetPageListData(parameter).then(res => {
        if (res.data.Status == 1) {
          this.total = res.data.Data.total
          this.tableData = res.data.Data.rows
        } else {
          alert(res.data.Message)
        }
        console.log(res);
      })
    },
    //确定
    determine() {
      if (this.type == 1) {
        const parameter = {
          role: {
            OrganizationId: this.OrganizationId,
            Name: this.Name,
            DataScope: this.DataScope,
          },
          permissions: this.permissions
        }
        administrators.AddJurisdictionData(parameter).then(res => {
          if (res.data.Status == 1) {
            this.GetPageList()
            console.log(res);
          } else {
            alert(res.data.Message)
          }

        })
      } else {
        const parameter = {
          role: {
            // OrganizationId:this.OrganizationId,
            Name: this.Name,
            DataScope: this.DataScope,
            Id: this.row.Id
          },
          permissions: this.permissions
        }
        administrators.EditJurisdictionData(parameter).then(res => {
          if (res.data.Status == 1) {
            this.GetPageList()
            console.log(res);
          } else {
            alert(res.data.Message)
          }
        })
      }
      this.dialogVisible = false
    },
    // //组织
    // NoPagingOrganization(){
    //     const parameter={
    //           Key:"",
    //     }
    //     administrators.NoPagingOrganizationData(parameter).then(res=>{
    //          console.log(res,"组织");
    //          if(res.data.Status==1){
    //             this.organization=res.data.Data
    //          }else{
    //           alert(res.data.Message)
    //         }
    //     })
    // },
    //打开角色弹框
    addRole() {
      //  this.OrganizationId=""
      this.Name = ""
      this.DataScope = '1'
      this.type = 1
      this.arr.forEach(item => {
        item.forEach((row, index) => {
          this.selectItemCheck[index] = false
          this.$set(row, 'flag', false)
        })
      })
      //  this.NoPagingOrganization()
      this.dialogVisible = true;
      this.permissions = [];
    },
    //复选框回调
    ChangeJurisdiction(e, item) {
      console.log(item);
      if (e) {
        let obj = {
          ModuleId: item.ModuleId,
          FunctionId: item.FunctionId,
        }
        this.permissions.push(obj)
      } else {
        this.permissions = this.permissions.filter(m => {
          if (!(item.ModuleId == m.ModuleId && item.FunctionId == m.FunctionId)) {
            return m
          }
        })
      }
      console.log(this.permissions);
    },
    //权限矩阵
    GetMap() {
      administrators.GetMapData().then(res => {
        console.log(res, '343');
        if (res.data.Status == 1) {
          res.data.Data.Modules.forEach((item) => {
            res.data.Data.Functions.forEach((row) => {
              this.list.push({
                ModuleName: item.ModuleName,
                ModuleId: item.ModuleId,
                FunctionName: row.FunctionName,
                FunctionId: row.FunctionId,
                flag: false,
              })
              if (this.list.length == res.data.Data.Functions.length) {
                console.log(this.list)
                this.arr.push(this.list)
                this.list = []
              }
            })

          })
        } else {
          alert(res.data.Message)
        }
      })
    },
    cancel() {
      //需要将permission制空，否则每次点击新增角色的时候重复会push数据，
      this.permissions = [];
      this.dialogVisible = false
    },
    //打开编辑
    EditJurisdiction(row) {
      let flag = null
      this.type = 2
      //  this.NoPagingOrganization()
      this.row = row
      const parameter = {
        id: row.Id,
      }
      administrators.GetRoleData(parameter).then(res => {
        console.log(res, '获取permissions');
        this.permissions = res.data.Data.permissions
        this.Name = res.data.Data.role.Name
        // this.OrganizationId=res.data.Data.role.OrganizationId
        this.DataScope = JSON.stringify(res.data.Data.role.DataScope);
        this.arr.forEach((item) => {

          item.forEach(row => {
            this.$set(row, 'flag', false)
            this.permissions.forEach(m => {
              if (row.ModuleId == m.ModuleId && row.FunctionId == m.FunctionId) {
                this.$set(row, 'flag', true)
              }
            })
          })
        })
        console.log(this.arr, "396");
        flag = this.arr.every(item => {
          return item.every(row => {
            return row.flag
          })
        })
        // this.checkAll=flag
        console.log(flag);
        this.dialogVisible = true
      })
    },
    //删除
    DeleteJurisdiction(row) {
      const parameter = []
      parameter.push(row.Id)
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        administrators.DeleteJurisdictionData(parameter).then(res => {
          console.log(res);
          if (res.data.Status == 1) {
            this.tableData = this.tableData.filter(item => {
              return item.Id != row.Id
            })
          } else {
            alert(res.data.Message)
          }
        })
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      })
    }
  },
  watch: {
    "arr": {
      handler(newVal, oldVal) {
        if (newVal) {
          this.arr.forEach((item, index) => {
            // if(item)
            this.selectItemCheck[index] = item.every((val) => {
              return val.flag
            })
          })
          this.checkAll = this.selectItemCheck.every((item) => item)
        }
      },
      deep: true,
    }
  }
}
</script>
<style lang='scss' scoped>
.box {
  width: 100%;
  padding: 20px 40px 40px 40px;
  box-sizing: border-box;
  height: 745px;

  .search {
    margin-bottom: 20px;
  }
}

.all {
  position: absolute;
}

.item_p_0 {
  margin-top: 22px;
}

.row_div {
  text-align: center;
  width: 60px;
  font-size: 16px;
}

::v-deep .el-checkbox__label {
  font-size: 16px;
  margin-left: 10px;
}
</style>
